import { useState, useRef, useEffect } from "react";
import {
  Button,
  Text,
  Dialog,
  Flexbox,
  TextField,
  Spacer,
  TextAreaField,
  useToast
} from "@skf-internal/ui-components-react";
import { detachRoleFromUser } from "../users/userAPI";


export interface IBulkRemoveRolesProps {
  usersList: any,
  auth: any,
  callback: any,
  roleId: any,
}

export default function BulkRemoveRoles(props: IBulkRemoveRolesProps) {
  const { usersList, auth, callback, roleId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [ticket, setTicket] = useState("");
  const [reason, setReason] = useState("");
  const [render, setRender] = useState(false)
  const [declineHint, setDecineHint] = useState("");
  const [declineError, setDeclineError] = useState<"error" | undefined>(
    undefined
  );

  const failureEmails: any = useRef('');
  const show: any = useRef(false);
  const userLength: any = useRef(0);
  const buttonRef: any = useRef(null)

  const { addToast } = useToast();

  let roleName: any = ""
  if (usersList.length > 0) {
    let temp = usersList[0]?.roles?.filter((data: any) => data?.roleId === roleId)
    if (temp?.length > 0) {
      roleName = temp[0]?.roleName;
    }
  }

  const resetStates = () => {
    if (show.current === true) {
      callback();
    }
    setTimeout(() => {
      failureEmails.current = "";
      show.current = false;
      userLength.current = 0;
      buttonRef.current.disabled = false;
    })

  }


  const confirmButtonOnClick = (e: any) => {
    // for the first time it will execute the following code , next time onwords button will be disabled
    if (buttonRef.current && !buttonRef.current.disabled) {
      buttonRef.current.disabled = true;
      setTimeout(() => {
        removeRoleFromUser()
      })
    }
  }

  // After API calls we will execute this logic
  const stepsAfterApiCall = (response: any) => {
    let totalArray = response.map((data: any, index: any) => {
      return (data?.status === "fulfilled" ? true : usersList[index])
    })

    let successArray = totalArray?.filter((data: any) => data === true);
    let failedArray = totalArray?.filter((data: any) => data !== true)

    if (successArray?.length === usersList?.length) {
      show.current = true;
      setTimeout(() => {
        addToast({
          children: "Role removed successfully for selected users",
          feSeverity: "success",
        });
      })
    }
    if (failedArray?.length > 0) {
      failedArray.forEach((data: any) => {
        failureEmails.current = failureEmails.current + data?.email + " "
      })
      show.current = true;
      setTimeout(() => {

        addToast({
          children: `The role removal failed for ${failedArray?.length} user(s)`,
          feSeverity: "error",
        });

        setRender(true)
      }, 1000)
    }

  }

  // calling the API here
  const removeRoleFromUser = async () => {
    let reasonText = reason;
    if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
      reasonText += " Refering SNOW Ticket: " + ticket;
    }
    let removeRolePromises: any = []
    userLength.current = usersList?.length;
    usersList.forEach(async (data: any) => {
      removeRolePromises.push(detachRoleFromUser(data.id, roleId, {
        adminId: auth?.idTokenClaims?.sub,
        reason: reasonText,
      }))
    })
    let response: any = [];
    response = Promise.allSettled(removeRolePromises).then(res => stepsAfterApiCall(res))

  };


  const renderBody = () => {
    let arr: any = failureEmails.current.trim().split(" ");
    if (failureEmails?.current !== "" && show.current) {
      return (
        <>
          <Text>The role removal was successful for {userLength.current - arr.length === 1 ? userLength.current - arr.length + " " + "user" : userLength.current - arr.length + " " + "users"}.</Text>
          <Spacer></Spacer>
          <Text>
            The role removal failed for the following {arr.length === 1 ? "user" : arr.length + " " + "users"}, Please try again.
          </Text>
          <div style={{ padding: "1rem 0rem", height: "13.5rem", overflow: "auto" }}>
            {
              arr.map((data: any, index: any) => {
                let textToDisplay = `${index + 1}. ${data} `
                return (
                  <>
                    <Text>{textToDisplay}</Text>
                    <Spacer></Spacer>
                  </>
                )
              })
            }
          </div>
        </>
      )
    } else if (failureEmails?.current === "" && show.current) {
      return (
        <div style={{ padding: "1rem 0rem", height: "13.5rem", overflow: "auto" }}>
          <Text>The role removal was successful for {userLength.current === 1 ? userLength.current + " " + "user" : userLength.current + " " + "users"}.</Text>
        </div>
      )
    }
    else {
      return (
        <>
          <Text>
            Are you sure you want to remove the <b>{roleName}</b> role from the  {usersList?.length === 1 ? "" : usersList?.length} selected {usersList?.length > 1 ? 'users' : 'user'}?
            <Spacer></Spacer>
            This action cannot be undone and may affect their access to certain features and resources.
          </Text>
          <Spacer feSpacing="md" />
          <TextAreaField
            style={{
              height: "60px",
              width: "470px",
              minWidth: "470px",
              maxWidth: "470px",
            }}
            feLabel="Reason (for internal use)"
            onChange={(_, value) => {
              setReason(value);
            }}
          />
          <Spacer feSpacing="md"></Spacer>
          {auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin") &&
            false && (
              <>
                <TextField
                  feLabel="Reference"
                  //feRequiredText={errorHint}
                  feHint={declineHint}
                  feSeverity={declineError}
                  onChange={(_, value) => {
                    setTicket(value);
                  }}
                />
                <Spacer feSpacing="md"></Spacer>
              </>
            )}
          <Spacer feSpacing="xs"></Spacer>
        </>
      )
    }
  }

  useEffect(() => {
   
  }, [render])

  return (
    <div style={{ padding: "12px 0px" }}>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        disabled={!(usersList?.length > 0)}
      >
        Remove role
      </Button>
      <Dialog
        feInterruptive
        feTitle="Remove role from selected users"
        open={isOpen}
        cssWidth={"36rem"}
        cssHeight={"25.4rem"}
      >
        {renderBody()}

        <Flexbox
          feGap="md"
          feJustifyContent="flex-end"
          feAlignItems="flex-end"
        >
          <Button
            feType="secondary"
            onClick={() => {
              setIsOpen(false);
              resetStates();
            }}
          >
            Cancel
          </Button>
          <Button
            ref={buttonRef}
            onClick={confirmButtonOnClick}
          >
            Confirm
          </Button>
        </Flexbox>
      </Dialog>
    </div>
  );
}