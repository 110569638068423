import {
  IconButton,
  TableCell,
  TableRow,
  Time,
  Tooltip,
  useTooltip,
  Checkbox
} from "@skf-internal/ui-components-react";
import { getFlag } from "../../Utils/getflag";
import { displayDate } from "../common/Common";
import { IUserData, IUserRole } from "./globalRoleUsersSlice";
import RoleTag from "./RoleTag";
import ViewUserLink from "./ViewUserLink";
import ShowDate from "../common/CustomDateTime";

export const createUsersRows = (
  stateUsers: any,
  auth: any,
  roleId: number,
  lastItemRef: any,
  reloadCallback: any,
  isSuperAdmin: boolean,
  selectedItemsForOperation:any
) => {

  if (stateUsers?.length > 0) {
    let slice: any[] = stateUsers;
    let formatRow = slice.map((item: any, index) => {
      
      if (slice.length === index + 1) {
        return {
          cells: [
            {
              children: (
                <Checkbox
                  feLabel=""
                  feSize="sm"
                  name = {item.id}
                  key={item?.email}
                  checked={item?.isChecked || false}
                  onChange={selectedItemsForOperation}
                />
              ),
             // height: "48px",
            },
            {
              children: (
                <span key={item.userId} title={item.firstName}>
                  {item.firstName}
                </span>
              ),
              height: "48px",
            },
            {
              children: <span title={item.lastName}>{item.lastName}</span>,
            },
            {
              children: <span title={item.email}>{item.email}</span>,
            },
            {
              children: (
                <div className="cellCountry" title={item.countryName}>
                  {item.countryIso?.length > 1 && (
                    <img
                      loading="lazy"
                      height="14"
                      srcSet={`https://flagcdn.com/w40/${getFlag(
                        item.countryIso
                      )}.png`}
                      src={`https://flagcdn.com/w40/${getFlag(
                        item.countryIso
                      )}.png`}
                      alt={item.countryName}
                      title={getFlag(item.countryName)}
                      className="flag"
                    />
                  )}
                  <span>{item.countryName}</span>
                </div>
              ),
            },
            {
              children: (
                <>
                  <span
                    style={{ display: "inline-flex", textWrap: "wrap" }}
                    ref={lastItemRef}
                    title={item.company}
                  >
                    {item.company}
                  </span>
                </>
              ),
            },
            {
              children: (
                <>
                  {splitRolesToTags(
                    item,
                    auth,
                    roleId,
                    reloadCallback,
                    isSuperAdmin
                  )}
                </>
              ),
              className: "TagCell",
            },
            {
              children: (
                <>
                  {item?.lastLogginAt ? (
                    <ShowDate dataTimeString={item.lastLogginAt} />
                  ) : (
                    ""
                  )}
                </>
              ),
              className: "dateCell",
            },
            {
              children: (
                <>
                  {item?.createdAt ? (
                    <ShowDate dataTimeString={item.createdAt} />
                  ) : (
                    ""
                  )}
                </>
              ),
              className: "dateCell",
            },
            {
              children: <ViewUserLink user={item} />,
              className: "dateCell",
            },
          ],
        };
      } else {
        return {
          cells: [
            {
              children: (
                <Checkbox
                  feLabel=""
                  feSize="sm"
                  name={item.id}
                  key={item?.email}
                  checked={item?.isChecked || false}
                  onChange={selectedItemsForOperation}
                />
              ),
              height: "48px",
            },
            {
              children: (
                <span key={item.userId} title={item.firstName}>
                  {item.firstName}
                </span>
              ),
              height: "48px",
            },
            {
              children: <span title={item.lastName}>{item.lastName}</span>,
            },
            {
              children: <span title={item.email}>{item.email}</span>,
            },
            {
              children: (
                <div className="cellCountry" title={item.countryName}>
                  {item.countryIso?.length > 1 && (
                    <img
                      loading="lazy"
                      height="14"
                      srcSet={`https://flagcdn.com/w40/${getFlag(
                        item.countryIso
                      )}.png`}
                      src={`https://flagcdn.com/w40/${getFlag(
                        item.countryIso
                      )}.png`}
                      alt={item.countryName}
                      title={getFlag(item.countryName)}
                      className="flag"
                    />
                  )}
                  <span>{item.countryName}</span>
                </div>
              ),
            },
            {
              children: (
                <>
                  <span
                    style={{ display: "inline-flex", textWrap: "wrap" }}
                    title={item.company}
                  >
                    {item.company}
                  </span>
                </>
              ),
            },
            {
              children: (
                <>
                  {splitRolesToTags(
                    item,
                    auth,
                    roleId,
                    reloadCallback,
                    isSuperAdmin
                  )}
                </>
              ),
              className: "TagCell",
            },
            {
              children: (
                <>
                  {item?.lastLogginAt ? (
                    <ShowDate dataTimeString={item.lastLogginAt} />
                  ) : (
                    ""
                  )}
                </>
              ),
              className: "dateCell",
            },
            {
              children: (
                <>
                  {item?.createdAt ? (
                    <ShowDate dataTimeString={item.createdAt} />
                  ) : (
                    ""
                  )}

                </>
              ),
              className: "dateCell",
            },
            {
              children: <ViewUserLink user={item} />,
            },
          ],
        };
      }
    });
    return formatRow;
  } else {
    return [];
  }
};
function splitRolesToTags(
  user: IUserData,
  auth: any,
  roleId: number,
  reloadCallback: any,
  isSuperAdmin: boolean
) {
  let roles: IUserRole[] = user.roles;
  let i: number = 0;
  let res: any;
  let count: number = 0;
  let rolesToolTip = roles
    .map((role) => {
      return role.roleName;
    })
    .join(",\n ");

  return (
    <>
      <div style={{ whiteSpace: "nowrap" }}>
        {roles.filter((p) => p.roleId == roleId).length > 0 && (
          <RoleTag
            role={roles.filter((p) => p.roleId == roleId)[0]}
            auth={auth}
            user={user}
            callback={reloadCallback}
            isSuperAdmin={isSuperAdmin}
          ></RoleTag>
        )}
        {roles.length > 1 && (
          <span
            className="additionalRoles"
            aria-label={"User has this roles:\n\n" + rolesToolTip}
          >
            <span className="counter"> +{roles.length - 1}</span>
          </span>
        )}
      </div>
    </>
  );
}
